.container {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  button{
    font-family: 'Roboto';
  }

.soup-fest-logo{
    margin-top: 10px;
    width: 80%;
    height: auto;
  }
  
  .label{
    margin: 20px 0 0 0;
    padding: 0;
  }
  

  .input{
    padding: 10px;
    border-radius: 10px;
    min-width: 200px;
    height: 20px;
    background-color: #ffd992;
  }
  
  .input:active{
    border-color: transparent;
  }

  .submitImg{
    width: 150px;
    height: auto;
  }
  .submitImg:active{
    opacity: 0.8;
  }

  .App-logo {
    height: 40vmin;
    pointer-events: none;
    margin-top: 20px;
  }

  .toast{
    position: fixed;
    bottom: 40px;
    background: #aa0122;
    color: white;
    left: 10%;
    right: 10%;
    padding: 20px;
    border-radius: 5px;
  }


  .close{
    width: 20px;
    height: 20px;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  
  .close:active{
    opacity: 0.8;
  }
  

  
@media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .button.danger{
    background-color: #aa0122;
    color: white;
  }

 
.Body_Container {
  height: calc(100vh - (60px + env(safe-area-inset-bottom)));
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: #222200;
  background-color: #e8bc6b;
  font-family:'Roboto', 'Trebuchet MS', sans-serif;
  font-weight: 100;
}
.memes_container{
  justify-content: start;
}

.memes_img{
  max-width: 100vw;
  /* more soup button */
  max-height: calc(100vh - 60px); 
  z-index: 8;
}

.memes_sparkles{
  width: 25px;
  height: 25px;
  margin-right: 5px;
}

.memes_moreSoupButtonContainer{
  width: 100%;
  height: 60px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.memes_moreSoupButton {
  color: black;
  border-radius: 40px;
  padding: 10px 20px;
  border-style: solid;
  box-shadow: none;
  background-color: white;
  border-color: transparent;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
}

.memes_moreSoupButton:active {
  background-color: #ffffffaa;
}
.results_Header{
    text-align: center;
    font-size: 34px;
    margin-bottom: 0px;
}

.results_list{
    overflow-y: auto;
    max-height: calc(100vh - 60px);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.results_Count{
    text-align: center;
}

.results_Container{
    border: 3px solid #964B00;
    border-radius: 50%;
    margin: 20px 20px;
    color: #444;
    position: relative;
    background-color: #d68B4044;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 160px;
    min-height: 160px;
}

.results_Container .name{
    font-size: 26px;
    text-align: center;
    margin-bottom: 0px;
}

.results_Container .thLabel{
    font-size: 12px;
    vertical-align: super;
}

.results_Container .trophyImg{
    position: absolute;
    top: 5px;
    left: calc(50% - 15px);
    right: calc(50% - 15px);
    width: 30px;
    height: 40px; 
}

.results_Container .placeLabel{
    position: absolute;
    width: 30px;
    top: 15px;
    left: calc(50% - 15px);
    right: calc(50% - 15px);
}

.results_Container .trophyCount{
    margin: 10px 0px;
}

.results_Container .totalContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.results_Container .total{
    font-size: 20px;
    font-weight: 600;
}

.results_votingInProgress {
    display: flex;
    flex-direction: column;
}
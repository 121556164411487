.NavigationBar_Container {
  height: 60px;
  width: 100%;
  background-color: #002200;
  position: sticky;
  top: 0;
}

.tab-button{
  background-color: transparent;
  height: 100%;
  width: 33.333vw;
  color: white;
  border: none;
}

.tab-button.selected {
  background-color: #004400;
}


.admin-menu_innerContainer{
  overflow-y: auto;
  max-height: calc(100vh - 60px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button{
    padding: 15px 25px;
    border-radius: 3px;
    background-color: #e88c31;
    border-color: transparent;
    min-width: 8em;
    min-height: 4em;
    color: black;
    -webkit-appearance: none;
  }
  
  .button:active{
    background-color: #c86c11;
  }

  .admin_actionsContainer{
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
  }